.edit-hotel {
  background: #fff;
  width: 794px;
  margin: 0 auto;
  padding: 42px 30px;
  margin-bottom: 20px;
  h3 {
    font-size: 24px;
    line-height: 35px;
    color: #0c0714;
    margin-bottom: 24px;
    font-weight: 600;
  }
  form {
    float: none;
    .inputField {
      display: inline-block;
      width: 49%;
      margin-right: 10px;
      &:nth-child(even) {
        margin-right: 0;
      }
      label {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.35px;
        color: #000000;
        display: block;
        font-weight: 600;
        margin-bottom: 8px;
      }
      select,
      input {
        border: 1px solid #bebebe;
        border-radius: 4px;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
        padding: 9px 16px;
        margin-bottom: 16px;
        background-color: #ffffff;
      }
    }
    .inputUrlField {
      width: 100% !important;
    }
    .selectCompetitor {
      .react-tagsinput-input {
        width: 100%;
      }
      h5 {
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.35px;
        color: #000000;
        display: block;
        font-weight: 600;
        margin-bottom: 8px;
      }
      .react-tagsinput {
        border: 1px solid #bebebe;
        border-radius: 4px;
        font-size: 16px;
        line-height: 22px;
        width: 100%;
        padding: 9px 16px;
        margin-bottom: 16px;
        .react-tagsinput-tag {
          background: #ebe8f0;
          border-radius: 3px;
          border: 1px solid #ebe8f0;
          letter-spacing: -0.31px;
          color: #0c0714;
          font-size: 14px;
          line-height: 20px;
        }
      }
    }
    .Btn-group {
      .cancel {
        border: 1px solid #d8d8d8;
        border-radius: 3px;
        background: #fff;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        padding: 15px 28px;
        color: #777777;
      }
      .save {
        background: #4c337b;
        border: 1px solid #4c337b;
        border-radius: 3px;
        font-size: 16px;
        line-height: 24px;
        display: inline-block;
        padding: 15px 28px;
        color: #fff;
        float: right;
      }
    }
  }
}

.MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper.MuiDialog-paperWidthSm.MuiDialog-paperFullWidth.makeStyles-paperFullWidth-2.MuiPaper-elevation24.MuiPaper-rounded {
  padding: 42px 36px;
  .MuiDialogTitle-root {
    h2.MuiTypography-root.MuiTypography-h6 {
      font-size: 24px;
      line-height: 35px;
      letter-spacing: 0px;
      color: #0c0714;
    }
    .MuiGrid-spacing-xs-3 > .MuiGrid-item {
      padding: 0;
      .content {
        padding: 0;
      }
    }
  }
  .MuiDialogContent-root.makeStyles-dialogContentRoot-3 {
    padding: 0;
    .content {
      padding: 0 16px;
      .MuiGrid-grid-xs-10 {
        width: 100%;
      }
      .MuiGrid-grid-xs-7 {
        width: 49%;
        display: inline-block;
      }
    }
  }
}
