.event-form-list-container {
  width: 80%;
  margin: auto;

  .hotel-name {
    // width: 50%;
    width: 400px;
  }

  .header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;

    .title {
      margin: 0;
      font-size: 30px;
      padding: 0;
    }
  }

  .list-table-container {
    .list-icons {
      svg {
        margin: 0 20px;
        height: 25px;
        width: 25px;
        cursor: pointer;
      }
    }

    .event-date-header {
      font-size: 16px;
      width: 420px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 14px;
      width: 420px;

      p {
        font-weight: bold;
        margin-bottom: 0;
      }

      svg {
        cursor: pointer;
      }
    }

    .events-card-container {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      gap: 10px;
      
      .delete-event-icon{
        cursor: pointer;
        margin-left: 4px;
        color: red;
      }
    }

    .event-card {
      width: 220px;
      min-height: 40px;
      height: auto;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 0px;
      padding: 4px 8px 5px 8px;

      p {
        margin: 0;
      }

      .event-info-container {
        .event-name {
          font-size: 11px;
        }

        .event-date {
          font-size: 9px;
        }
      }

      .event-price-info-container {
        display: flex;
        align-items: center;
        gap: 10px;

        p {
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
          padding-right: 15px;
          border-right: 1px solid #94a3b2;
        }

        img {
          width: 11px;
          cursor: pointer;
        }
      }
    }
  }
}
