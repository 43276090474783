.hotel-list-container {
	.button-wrapper {
		text-align: right;
		width: 100%;
		.btn {
			background: #4c337b;
			color: #fff;
			border: 1px solid #4c337b;
			border-radius: 3px;
			padding: 5px 19px;
			margin-bottom: 15px;
			font-size: 16px;
			line-height: 24px;
			font-weight: 600;
			margin: 10px;
		}
	}
	.list-header {
		background: #4c337b;
		position: relative;
		border-radius: 10px 10px 0 0;
		width: 100%;
		li {
			padding: 14px 10px 13px;
			font-size: 18px;
			line-height: 21px;
			color: #fff;
			font-weight: 600;
			display: inline-block;
			width: 16%;
			text-align: center;
			border-right: 1px solid #907db4;
			&:last-child {
				border-right: none;
			}
		}
	}
	.list-body {
		width: 100%;
		.list-row {
			display: block;
			background-color: #fff;
			margin-bottom: 7px;
		}
		.list-data {
			display: flex;
			li {
				display: inline-block;
				padding: 16px 10px 14px;
				font-size: 16px;
				line-height: 24px;
				border-right: 1px solid #707070;
				color: #0c0714;
				width: 16%;
				text-align: center;
				vertical-align: middle;
				word-break: break-all;
				&.hotel-name {
					font-weight: 600;
				}
				&:last-child {
					border-right: none;
				}
			}
		}
	}
}

.hotel-list-bottom-container {
	width: 100%;
	.list-data {
		width: 100%;
		.hotel-name {
			background: #fff;
			margin-bottom: 15px;
			display: flex;
			font-weight: 600;
			> span {
				width: 16%;
				text-align: left;
				padding: 16px 24px 14px;
				border-right: 1px solid #707070;
				font-size: 17px;
			}
			> ul {
				width: calc(100% - 16%);
				display: flex;
				li {
					padding: 16px 24px 14px;
					border-right: 1px solid #707070;
					margin: 0;
					width: 19%;
					font-size: 17px;
					&:last-child {
						border-right: none;
					}
				}
			}
		}
	}
}

.popup {
	max-width: 800px;
	margin: auto;
}

.popup .heading {
	font-size: 28px;
	font-weight: bold;
}
.quality {
	display: flex;
}
.w-70 {
	width: 70%;
}
.w-30 {
	width: 30%;
}

.text-section h2 {
	padding-top: 15px;
	font-size: 18px;
	font-weight: bold;
}

input[type='number']::-webkit-inner-spin-button,
input[type='number']::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

input[type='number'] {
	-moz-appearance: textfield;
}

.input-number input {
	margin-top: 15px;
	margin-left: 20px;
	width: 60px;
	height: 40px;
	border: 2px solid #532780;
	outline: 0;
	border-radius: 10px;
	line-height: 40px;
	text-align: center;
}
.input-number1 {
	margin-left: 20px;
}
.input-number1 input {
	margin-top: 25px;
	text-align: center;
	font-size: 18px;
	border-bottom: 2px solid #532780;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	outline: 0;
	width: 120px;
}

.input-number2 input {
	margin-top: 5px;
	margin-left: 20px;
	background-color: #532780;
	color: #fff;
	border: 0;
	outline: 0;
	width: 70px;
	height: 40px;
	text-align: center;
}

.input-number2 input::placeholder {
	color: #fff;
	opacity: 1;
}
.sort-heading {
	width: 70px;
	text-align: center;
	margin-left: 20px;
}
.sort-heading2 {
	width: 70px;
	text-align: center;
	margin-left: 40px;
}
.sort-heading p,
.sort-heading2 p {
	text-align: center;
	margin-bottom: 5px;
}

.font-weight-500 {
	font-weight: 600;
}
.middle {
	margin-left: 10px;
	font-size: 20px;
	font-weight: bold;
}

.set {
	font-size: 20px;
	font-weight: 600;
	margin-top: 15px !important;
}

.input-number3 {
	text-align: center;
}
.input-number3 input {
	margin-top: 5px;
	border-bottom: 2px solid #532780;
	border-top: 0;
	border-left: 0;
	border-right: 0;
	outline: 0;
	width: 200px;
	text-align: center;
	font-size: 18px;
}
.btn1 {
	background-color: #717171;
	border-radius: 20px;
	padding: 5px 30px;
	color: #fff;
}
.btn2 {
	background-color: #532780;
	border-radius: 20px;
	padding: 5px 30px;
	color: #fff;
}
.input-number5 p {
	text-align: center;
}

.input-number2 {
	width: 102px;
	height: 100px;
}

.input-number1.error input {
	border-bottom: 2px solid red;
}

.input-number.error input {
	border: 2px solid red;
}

.input-number2.error input {
	border: 2px solid red;
}
.input-number3.error input {
	border-bottom: 2px solid red;
}

.err-msg {
	color: red;
	display: none;
	font-size: 14px;
	margin-top: 5px;
	line-height: 1.1;
}
.error .err-msg {
	display: block;
}
.pad-20 {
	padding-right: 25px;
}

@media screen and (min-width: 768px) and (max-width: 1199px) {
	.popup {
		max-width: 700px;
		margin: auto;
	}
	.set {
		font-size: 16px;
		font-weight: 600;
		margin-top: 15px;
	}
	.input-number5 p {
		text-align: center;
	}
	.sort-heading,
	.sort-heading2 {
		margin-top: 5px;
		margin-bottom: 0;
	}
	.pad-20 {
		padding-left: 0;
	}
}

@media screen and (max-width: 767px) {
	.err-msg {
		font-size: 11px;
	}
	.popup {
		max-width: 100%;
		margin: auto;
	}
	.set {
		font-size: 13px;
		margin-top: 10px;
	}
	.input-number2 input {
		margin-top: 5px;
		margin-left: 5px;
		background-color: #532780;
		color: #fff;
		border: 0;
		outline: 0;
		width: 45px;
		height: 30px;
		text-align: center;
	}
	.input-number3 {
		text-align: left;
	}
	.input-number3 input {
		margin-top: 5px;
		border-bottom: 2px solid #532780;
		border-top: 0;
		border-left: 0;
		border-right: 0;
		outline: 0;
		width: 70px;
	}
	.middle {
		margin-left: 5px;
		font-size: 20px;
	}
	.sort-heading {
		width: 53px;
		text-align: center;
		margin-left: 0px;
	}
	.sort-heading2 {
		width: 70px;
		text-align: center;
		margin-left: 0px;
	}
}
