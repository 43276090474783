.userManagment{
  .userCategoryContainer{
      .totalProjects{
          color: black;
      }
      .currentProjects{
          color: green;
      }
      .dlpProjects{
          color: yellowgreen;
      }
      .lostProjects{
          color: purple;
      }
      .completedProjects{
          color: orangered;
      }
      .cancelledProjects{
          color: red;
      }
      
  }
  .tableContainer{
      margin-top: 15px;
  }
  .headingContainer{
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      margin-bottom: 10px;
  }
  .button{
      background: #014671;
      font-size: 11px;
      color: white;
      margin-left: 10px;
      box-shadow: none;
      border: 1px solid #014671;
  }
  button.user-role-btn {
      background-color: #fff;
      color: #014671;
  }
  .userOverviewText{
      font-size: 18px;
      font-weight: 600;
  }
  .tableInfoIcon {
      margin: 0px 0 -5px 10px;
      cursor: pointer;
  }
}
.addProject{
  .paperContainer{
      min-height: auto;
      height: auto;
      padding: 20px;

      
      .label{
          font-size: 14px;
          margin-top: 10px;
          text-align: left;
          color: #000;
          display: flex;
          align-items: center;
      }
      .addNewRoleCol {
          display: flex;
          .addNewRole{
              width: 60%;
              display: flex;
              align-items: center;
              justify-content: center;
              cursor: pointer;
              color: #014671;
              font-weight: 600;
              font-size: 15px;
          }
          .addNewRole:hover {
              color: #000!important;
          }
          .MuiInputBase-multiline.MuiInputBase-marginDense {
              padding: 0px!important;
          }
      }
      .MuiGrid-item {
          text-align: left;
          margin: 0px;
      }
      .modify {
          text-align: left;
          margin: 13px 0px;
          font-size: 17px;
          font-weight: 700;
          color: #014671!important;
          text-decoration: none!important;
          cursor: pointer;
      }
      .modify:hover {
          color: #000!important;
      }
      .MuiOutlinedInput-inputMarginDense {
          background: #F4F4F4;
      }
      .MuiFormControl-marginDense {
          width: 100%;            
      }
      .button{
          display: block;
          background: #B2C7D4;
          font-size: 11px;
          color: white;
          box-shadow: none;
          border: 2px solid #B2C7D4;
          margin-right: 10px;
      }
      .button:hover {
          background-color: #014671;
          color: #fff;
          border: 2px solid #014671;
      }
      p:hover{
          cursor: pointer;
      }
      .buttonContainer{
         justify-content: flex-end; 

      }
      .modify{
          text-align: left;
          margin: 13px 0px;
          font-size: 17px;
          font-weight: 700;
          color: blue;
          text-decoration: underline;
          cursor: pointer;
      }

  }
  
  .topNavigation{
      text-align: left;
      margin: 10px 0 20px;
      font-weight: 500;
      font-size: 17px;
      a{
          text-decoration: none;
          color: #014671;
      }
      a:hover{
          cursor: pointer;
      }
  }
}
.addItems{
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDens {
  padding: 0px!important;
  }
}
.newUser {
  .UserNameCol {
      margin-bottom: 30px;
      .selectUserRole {
          display: flex;
          align-items: center;
      }
      .MuiFormControl-marginDense {
          width: 100%;
          background: #f3f3f3;
      }
  }
  table {
      width: 100%;
      .MuiSwitch-thumb {
          background-color: #014671;
      }
      .MuiSwitch-track {
          background-color: #B2C7D4;
      }
      tr:first-child {
          line-height: 40px;
      }
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
      background-color: #014671;
  }
}

.createBtn {
  margin: 20px !important;
  background-color: #014671 !important;
  text-transform: unset !important;
}

.cancelBtn {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
  background-color: #fff !important;
  text-transform: unset !important;
}  

.txtarea {
  width: 100%;
}

.switch{
  .MuiSwitch-thumb {
      background-color: #014671;
  }
  .MuiSwitch-track {
      background-color: #B2C7D4;
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: #014671;
}
}

.error{
  font-size: 0.75rem;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif;
  color: #f44336;
  float: left;
}

.cost{
  .MuiInputBase-root{
      background:#f4f4f4;
  }
}

.addUnitContainer{
  .MuiSelect-selectMenu{
      white-space: unset;
  }
}
.categoryCard{
  .selectedFilter{
      background: #fff !important;
      cursor: pointer;
  }
  
  .normalFilter{
      background: #f6f6f6 !important;
      cursor: pointer;
  }
}

.desc {
  .MuiOutlinedInput-multiline.MuiOutlinedInput-marginDense{
      background: #f4f4f4;
  }
}

.levelDesc .desc textarea.MuiInputBase-inputMarginDense {
  padding: 0px!important;
}

.mapHotelGrid {
    .chip {
        white-space: initial;
    }
}