.hotel-wrapper {
  background-color: #eae8ec;
  .container {
    padding: 0 20px;
  }
}
.hotel-container {
  width: 100%;
  margin-top: 15px;
  ul {
    li {
      background-color: #fff;
    }
  }

  .accordion.ui {
    margin-bottom: 15px;

    .title,
    .content {
      position: relative;
      padding: 0 !important;
      margin: 0;
      &.active {
        .hotel-name {
          p {
            font-weight: 700;
          }
        }
        .hotel-data-container {
          display: flex;
          li {
            font-weight: 700;
          }
        }
      }
      .dropdown {
        position: absolute;
        left: 12%;
        top: 20px;
      }
      .accordion-bottom-section {
        display: flex;
        .left-container {
          .hotel-bottom-container.occupancy{
            margin: 15px 0;
            margin-bottom: 35px;
          }
          .hotel-bottom-container.AIPrice{
            margin-top: 10px;
          }
          width: 20%;
          vertical-align: top;
          border-right: 1px solid rgba(204, 204, 204, 0.5);
          padding: 8px 16px;
          .hotel-bottom-container {
            .occupancy-title,
            .AIprice-title {
              padding-bottom: 8px;
              height: 20px;
              p {
                font-size: 16px;
                line-height: 25px;
                margin-bottom: 30px;
              }
            }
            &.AIPrice {
              .AIprice-title {
                p {
                  margin-bottom: 15px;
                  font-size: 13px;
                }
              }
            }
            &.graph {
              .occupancy-title {
                padding-top: 40px;
              }
            }
          }
        }
        .right-container {
          .AIprice-data-container {
            span {
              color: #a1a1a1;
              position: relative;
              &::before {
                content: "";
                width: calc(100% + 10px);
                position: absolute;
                bottom: -5px;
                background: #a1a1a1;
                height: 2px;
                left: -4px;
              }
            }
          }
          .occupancy-data-container,
          .AIprice-data-container {
            display: flex;
            border-top: 2px solid rgba(204, 204, 204, 0.5);
            li {
              display: inline-block;
              width: 81px;
              padding: 20px 8px;
              text-align: center;
              position: relative;
              border-bottom: 1px solid rgba(204, 204, 204, 0.5);
              border-right: 1px solid rgba(204, 204, 204, 0.5);
              input {
                position: absolute;
                left: 0;
                //background: none;
                top: 0;
                padding: 20px 8px;
                text-align: center;
                border: none;
                display: none;
                width: 100%;
                &.show {
                  display: block;
                }
              }
            }
          }
          .occupancy-data-container {
            li {
              border-top: 1px solid rgba(204, 204, 204, 0.5);
              .varibale-price {
                position: absolute;
                top: 4px;
                font-size: 12px;
                line-height: 1;
                color: red;
                &.positive {
                  color: #11b711;
                }
              }
            }
          }
        }
      }
    }
  }
  .hotel-top-container {
    display: flex;
    .hotel-name {
      padding: 14px 16px 2px;
      width: 20%;
      border-right: 1px solid rgba(204, 204, 204, 0.5);
      position: relative;
      p {
        font-size: 13px;
        line-height: 25px;
        font-weight: 600;
      }
      .dropdown.icon {
        position: absolute;
        right: 0;
        left: auto;
        margin: 0;
      }
    }
    .hotel-data-container,
    .occupancy-data-container,
    .AIprice-data-container {
      display: flex;
      li {
        width: 81px;
        padding: 20px 8px;
        text-align: center;
        position: relative;
        //border-bottom: 1px solid rgba(204,204,204,.5);
        border-right: 1px solid rgba(204, 204, 204, 0.5);
        display: flex;
        justify-content: center;
        align-items: center;
        .varibale-price {
          position: absolute;
          top: 4px;
          font-size: 12px;
          line-height: 1;
          color: red;
          &.positive {
            color: #11b711;
          }
        }
      }
    }
  }
}

.price {
  background: #4c337b;
  border: none;
  border-radius: 3px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  margin-right: 10px;
  padding: 7px 10px;
  &.update {
    width: 100%;
  }
}
.cancel {
  background: #ffffff;
  border: 1px solid #d8d8d8;
  border-radius: 3px;
  font-size: 14px;
  line-height: 24px;
  color: #fff;
  padding: 7px 7px;
  color: #777777;
  margin-right: 10px;
}

.hotel-bottom-container {
  &.competitor {
    border-top: 1px solid rgba(204, 204, 204, 0.5);
    .occupancy-title {
      padding: 0;
      ul {
        li {
          width: 100%;
          padding: 15px 0 8px 17px;
          border-bottom: 1px solid rgba(204, 204, 204, 0.5);
        }
      }
    }
    .varibale-price {
      position: static !important;
      display: inline-block;
      margin-left: 5px;
    }
  }
  &.AIPrice {
    .AIprice-title {
      p {
        font-size: 13px;
        img {
          margin-right: 5px;
          display: inline-block;
        }
      }
    }
  }
  &.graph {
    .occupancy-title {
      ul {
        li {
          &:last-child {
            margin-top: 16px;
            &::before {
              content: "";
              width: 12px;
              background-color: #4c337b;
              height: 12px;
              margin-right: 5px;
              display: inline-block;
              border-radius: 100%;
            }
          }
        }
      }
    }
  }
}

.accordion-bottom-container {
  .competitor-container {
    display: flex;
    .left-container {
      display: inline-block;
      width: 20%;
      vertical-align: top;
      border-right: 1px solid rgba(204, 204, 204, 0.5);
      border-bottom: 2px solid rgba(204, 204, 204, 0.5);
      padding: 8px 16px;
      p {
        font-size: 13px;
        line-height: 25px;
        margin-bottom: 0;
        padding-top: 12px;
      }
    }
    .right-container {
      display: flex;
      border-bottom: 2px solid rgba(204, 204, 204, 0.5);
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 81px;
        padding: 20px 8px;
        text-align: center;
        position: relative;
        border-right: 1px solid rgba(204, 204, 204, 0.5);
        border-top: 1px solid rgba(204, 204, 204, 0.5);
      }
    }
  }
}

g[aria-label="Chart created using amCharts library"] {
  display: none;
}

.graphContainer-outer {
  position: relative;
  height: 100%;
  min-height: 200px;
  ul {
    border-top: 2px solid rgba(204, 204, 204, 0.5);
    border-bottom: 2px solid rgba(204, 204, 204, 0.5);
    height: 100%;
    min-height: 200px;
    li {
      width: 81px;
      height: 100%;
      border-right: 1px solid rgba(204, 204, 204, 0.5);
      display: inline-block;
    }
  }
}
.graph-container {
  width: 100%;
  height: calc(100% - 130px);
  min-height: 200px;
  position: absolute;
  top: 0;
  left: 0px;
}

.hotel-events-container {
  .hotel-events-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid gray;
    padding-bottom: 10px;
    align-items: center;
    flex-wrap: wrap-reverse;

    .hotel-events-header-1 {
      display: flex;
      align-items: center;

      p {
        margin-bottom: 0;
      }

      svg {
        margin: 0 10px;
        cursor: pointer;
      }
    }

    .hotel-events-header-2 {
      border-radius: 4px;
      display: flex;
      gap: 5px;
      border: 1px solid;
      padding: 5px 13px;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      height: 32px;
    }
  }

  .hotel-events-list {
    max-height: 200px;
    min-height: 150px;
    padding: 10px 20px;
    overflow-y: auto;

    .event-card {
      width: 100%;
      margin: 10px 0;
      min-height: 40px;
      height: auto;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
      gap: 0px;
      padding: 4px 8px 5px 8px;

      p {
        margin: 0;
      }

      .event-info-container {
        .event-name {
          font-size: 11px;
        }

        .event-date {
          font-size: 9px;
        }
      }

      .event-price-info-container{
        display: flex;
        align-items: center;
        gap: 10px;

        p{
          font-size: 14px;
          font-weight: bold;
          padding: 5px;
          padding-right: 15px;
          border-right: 1px solid #94a3b2;
        }

        img{
          width: 11px;
          cursor: pointer;
        }
      }
    }
  }
}


@media only screen and (max-width: 1770px) {
  .hotel-events-container {
    .hotel-events-header {
      justify-content: center;
      gap: 10px;
    }

    // .hotel-events-list {
    //   max-height: 130px;
    // }
  }
}