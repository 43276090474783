.notification_bar {
    .ai-price-generator{
        padding: 5px 10px;
        background: #4c337b;
        border-radius: 10px;
        color: white;
        cursor: pointer;
    }

    display: flex;
    justify-content: end;
    align-items: center;
    gap: 30px;

    float: left;
    padding-left: 24px;
    padding-top: 10px;
    > a {
        padding-right: 24px;
        display: inline-block;
        cursor: pointer;
        color: #4C337B;
        font-weight: 700;
        &.logout{
            img{
                width: 25px;
                margin-right: 10px;
            }
        }
    }
}