.header{
    padding: 15px 0 13px;
    background: #fff;
    position: fixed;
    width: 100%;
    z-index: 2;
    .header-right {
        float: right;
    }
    .header_profile {
        float: left;
        padding-top: 5px;
        .profile_icon{
            img{
                width: 37px;
                height: 37px;
                border-radius: 100%;
            }
        }
    }
}

.mainComponent{
    padding-top: 94px;
    &.scroll{
        .calendar-container {
            position: fixed;
            top: 70px;
            z-index: 3;
            width: 100%;
        }
        .hotel-wrapper{
            padding-top: 142px;
        }
    }
}

