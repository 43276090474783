/****************reset css starts here*****************/

h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
li,
body,
html,
form,
fieldset,
figure {
  margin: 0;
  padding: 0;
  outline: none;
  font-weight: normal;
  border: 0;
  line-height: normal;
}
a:hover{text-decoration: none}

input::-ms-clear, input::-ms-reveal {
  display: none;
}


input,
a,button {
  outline: none!important;
}


form,
fieldset {
  float: left;
  width: 100%
}

img {
  border: 0 none;
  max-width: 100%;
  max-height: 100%;
  height: auto;
  vertical-align: middle
}

a,a:hover,a:focus {
  text-decoration: none;
  border: 0;
  outline: none!important;
}

ul {
  list-style: none;
}

.clear {
  clear: both;
}

html,
body {
  height: 100%;
  
}

body {
  font-family: 'Source Sans Pro', sans-serif;
  color: #000;
  background-color: #EAE8EC !important;
  overflow-x: hidden;
  overflow-y: scroll;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* container section start here */

.container {
  margin: 0 auto;
  max-width: 100%;
  padding: 0 20px;
}

// Calendar Css 

.calendar-container {
  background: #F1EFF3;
  #datePicker{
      margin: 0;
      width: 100%;
      background: #4C337B;
      border-radius: 10px 10px 0 0;
      .dateText {
        color: #fff;
        top: 11px;
        position: relative;
        font-size: 16px;
        line-height: 24px;
      }
      .downloadIcon {
        cursor: pointer;
        width: 25px;
        margin-left: 50px;
        top: 10px;
        position: relative;
        img {
          width: 25px;
        }
      }
      input[type="date"]{
        margin-bottom: 0;
        height: auto;
        padding: 9px 0;
        width: 80px;
        color: transparent;
        background-color: transparent;
        text-align: center;
        border: 0;
        position: relative;
        line-height: 24px;
        font-size: 0;
        &::-webkit-calendar-picker-indicator {
          color: rgba(0, 0, 0, 0);
          opacity: 1;
          display: block;
          background: url(/images/calendar-icon.svg) no-repeat;
          width: 18px;
          height: 18px;
          border-width: thin;
          position: absolute;
          left: 0;
      }
      }
     
    }
  }

