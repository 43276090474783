.sidebar {
    position: fixed;
    top: 71px;
    width: 250px;
    height: 100vh;
    webkit-transition: transform .8s cubic-bezier(.2,1,.3,1);
    transition: transform .8s cubic-bezier(.2,1,.3,1);
    -ms-transform: translateX(-466px) translateZ(0);
    -webkit-transform: translateX(-466px) translateZ(0);
    background: #fff;
    padding: 20px;
    > a {
        display: block;
        padding: 17px;
        color: #777;
        font-size: 16px;
        line-height: 22px;
        padding-left: 41px;
    }
    .closebtn {
        position: absolute;
        right: 20px;
        top: 0;
        width: 20px;
        height: 20px;
        font-size: 30px;
    }
    .dashBoard{
        background: url('../../images/dashboard.svg') no-repeat center left;
    }
    .admin{
        background: url('../../images/admin.svg') no-repeat center left;
    }
    .userManagement{
        background: url('../../images/usernanagement.svg') no-repeat center left;
    }
    .eventManagement{
        background: url('../../images/usernanagement.svg') no-repeat center left;
    }
}

.sideBar{
    .mainComponent {
        webkit-transition: transform .8s cubic-bezier(.2,1,.3,1);
        transition: transform .8s cubic-bezier(.2,1,.3,1);
        -ms-transform: translateX(-466px) translateZ(0);
        -webkit-transform: translateX(-466px) translateZ(0);
        transform: translateX(250px) translateZ(0);
        overflow: hidden;
    }
    .sidebar{
        webkit-transition: transform .8s cubic-bezier(.2,1,.3,1);
        transition: transform .8s cubic-bezier(.2,1,.3,1);
        -ms-transform: translateX(0) translateZ(0);
        -webkit-transform: translateX(0) translateZ(0);
    }
}

.openbtn{
    background: transparent;
    color: #777;
    border: none;
    font-size: 19px;
    margin-right: 10px;
    padding: 0;
}