.daySelectArea {
	background: #4c337b;
	display: flex;
	width: 100%;
	box-shadow: 0px 2px 4px #00000029;
	border-top: 1px solid #907db4;
	.daySelectLeft {
		width: 20%;
	}
	.daySelectRight {
		position: relative;
		ul {
			display: flex;
			li {
				border-right: 1px solid #907db4;
				width: 81px;
				padding: 7px 8px;
				text-align: center;
				&:last-child {
					border-right: 0;
				}
				span {
					font-size: 16px;
					line-height: 24px;
					color: #fff;
					display: block;
				}
			}
		}
		.calendar-left-arrow {
			position: absolute;
			left: -20px;
			top: 50%;
			margin-top: -15px;
			cursor: pointer;
		}
		.calendar-right-arrow {
			position: absolute;
			right: 0;
			top: 50%;
			margin-top: -15px;
			cursor: pointer;
		}
	}
}

// Loader Styling

@-webkit-keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
	}
}

@keyframes sk-bouncedelay {
	0%,
	80%,
	100% {
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	40% {
		-webkit-transform: scale(1);
		transform: scale(1);
	}
}

.spinnerBg {
	/*  position: relative;
      top: -110px;
      z-index: 9999;*/
	z-index: 999999999999 !important;
	position: fixed;
	padding: 20px 0 40px;
	margin: 0 auto;
	top: 0;
	background: rgba(0, 0, 0, 0.5);
	z-index: 9;
	width: 100%;
	height: 100%;
	overflow: hidden;
	top: 0;
	left: 0;
}

.spinner {
	border-radius: 50%;
	-o-border-radius: 50%;
	-moz-border-radius: 50%;
	-webkit-border-radius: 50%;
	-ms-border-radius: 50%;
	width: 46px;
	height: 46px;
	background: #4c337b;
	text-align: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	position: absolute;
	top: 50%;
	left: 50%;
}

.spinner > div {
	width: 9px;
	height: 9px;
	background-color: #fff;
	border-radius: 100%;
	display: inline-block;
	-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
	animation: sk-bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
	-webkit-animation-delay: -0.32s;
	animation-delay: -0.32s;
}

.spinner .bounce2 {
	-webkit-animation-delay: -0.16s;
	animation-delay: -0.16s;
}
.align-items {
	align-items: center;
}
.MuiMenu-paper {
	top: 145px;
}

.calendar-container {
	.MuiOutlinedInput-notchedOutline {
		border-color: #fff !important;
	}
	.MuiInputLabel-outlined,
	.MuiOutlinedInput-input,
	.MuiSvgIcon-root {
		color: #fff !important;
	}
}

.dashboard-pagination {
	.MuiPaginationItem-outlinedPrimary.Mui-selected {
		color: #fff;
		border: 1px solid #4c337b;
		background-color: #4c337b;
	}
	.MuiPaginationItem-outlined {
		border: 1px solid #4c337b;
		color: #4c337b;
	}
}
