
.rbc-overlay{
    z-index: 1000000 !important;
}

.rbc-calendar{
    padding: 20px;
    padding-top: 0;
}

.rbc-toolbar-label{
    font-size: 28px;
    font-weight: bolder;
}

.rbc-event, .rbc-day-slot .rbc-background-event{
    background-color: #4c337b;
}

.rbc-show-more{
    color: #4c337b;
}

.rbc-toolbar  {

    button{
        border: none ;
    }

    button:hover{
        background-color: #ffffff !important ;
        border: 0 !important;
    }
}
