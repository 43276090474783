.search-container{
    float: left;
    position: relative;
    input{
        border:1px solid #DBDADE;
        border-radius: 21px;
        font-size: 16px;
        line-height: 22px;
        width: 292px;
        padding: 9px 16px 9px 40px;
        color: #BDBCBF;
    }
    .search_button {
        position: absolute;
        left: 16px;
        top: 50%;
        margin-top: -11px;
        .search_btn {
            width: 24px;
            height: 24px;
            color: #BDBCBF;
        }
    }
    .expand_more {
        position: absolute;
        right: 16px;
        top: 50%;
        margin-top: -13px;
    }
    .expand_mre {
        width: 24px;
        height: 24px;
        color: #BDBCBF;
    }
}