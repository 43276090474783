.login {
  display: flex;
  height: 100vh;
  background: #fff;
  justify-content: center;
  align-items: center;
  background: url(../../../images/images/login.png) no-repeat;
  padding: 15px;
  background-size: cover;
  .logo {
    font-size: 16px;
    line-height: 22px;
    color: #fff;
    width: 49%;
    display: inline-block;
    margin-right: 10px;
    max-height: none;
    padding-left: 50px;
    p {
      margin-top: 24px;
    }
  }
  .loginForm {
    width: 49%;
    padding-left: 50px;
    .loginFormStart {
      max-width: 381px;
      margin: 0 auto;
      background-color: #fff;
      padding: 30px;
    }
    p.LoginHeading {
      font-size: 24px;
      font-weight: 600;
      margin-top: 0px;
      margin-bottom: 30px;
      line-height: 35px;
      letter-spacing: -0.53px;
      color: #0c0714;
    }
    label.LoginLabel {
      font-size: 16px;
      color: #0c0714;
      display: block;
      padding-bottom: 6px;
      line-height: 22px;
      font-weight: 600;
      margin: 0;
    }
    .MuiOutlinedInput-root {
      margin-bottom: 15px;
      input {
        border: 1px solid #bebebe;
        border-radius: 4px;
        font-size: 16px;
        line-height: 22px;
        color: #16123b;
      }
    }
    .MuiFormControl-marginDense {
      width: 100%;
      margin: 0;
    }
    .ForgotDiv {
      text-align: left;
      .MuiSvgIcon-root {
        width: 16px;
        height: 16px;
      }
      .PrivateSwitchBase-root-10 {
        padding: 0 8px 0 20px;
      }
      .MuiTypography-body1 {
        font-size: 14px;
      }
      p {
        font-size: 14px;
        display: inline-block;
        margin: 0px;
        color: #014671;
        float: right;
        line-height: 25px;
      }
    }
    .MuiFormLabel-root.Mui-focused {
      color: #4c337b;
      box-shadow: 2px 3px 6px #0000001f;
    }
  }
  .loginButton {
    background-color: #4c337b !important;
    color: #fff;
    width: 100%;
    font-size: 14px;
    text-transform: unset;
    margin-top: 30px;
    line-height: 20px;
    padding: 12px 10px;
  }

  input:-internal-autofill-selected {
    background-color: #fff !important;
  }
}
