.user {
  h3 {
    display: inline-block;
    font-size: 32px;
    line-height: 46px;
    font-weight: bold;
  }
  .userManagement {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  .headerLeft {
    width: 100%;
  }
  .headerRight {
    width: 100%;
    display: flex;
    justify-content: end;
    align-items: center;
    .searchBox {
      width: 70%;
    }
  }
  .button-wrapper {
    width: auto;
    display: inline-block;
  }
  .list-header {
    li {
      text-align: left;
      padding: 14px 24px 13px;
    }
  }
  .list-body {
    .list-data {
      li {
        width: 16%;
        text-align: left;
        padding: 16px 24px 14px;
        &:last-child {
          > span {
            display: inline-block;
            &.edit {
              padding-right: 24px;
              cursor: pointer;
            }
            &.delete {
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}

.userManagment {
  .userCategoryContainer {
    .totalProjects {
      color: black;
    }
    .currentProjects {
      color: green;
    }
    .dlpProjects {
      color: yellowgreen;
    }
    .lostProjects {
      color: purple;
    }
    .completedProjects {
      color: orangered;
    }
    .cancelledProjects {
      color: red;
    }
  }
  .tableContainer {
    margin-top: 15px;
  }
  .headingContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .button {
    background: #014671;
    font-size: 11px;
    color: white;
    margin-left: 10px;
    box-shadow: none;
    border: 1px solid #014671;
  }
  button.user-role-btn {
    background-color: #fff;
    color: #014671;
  }
  .userOverviewText {
    font-size: 18px;
    font-weight: 600;
  }
}
.addUser {
  .paperContainer {
    min-height: auto;
    height: auto;
    padding: 20px;

    .label {
      font-size: 14px;
      margin-top: 10px;
      text-align: left;
      color: #000;
      display: flex;
      align-items: center;
    }
    .addNewRoleCol {
      display: flex;
      .addNewRole {
        width: 60%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: #014671;
        font-weight: 600;
        font-size: 15px;
      }
      .addNewRole:hover {
        color: #000 !important;
      }
    }
    .MuiGrid-item {
      text-align: left;
      margin: 0px;
    }
    .modify {
      text-align: left;
      margin: 13px 0px;
      font-size: 17px;
      font-weight: 700;
      color: #014671 !important;
      text-decoration: none !important;
      cursor: pointer;
    }
    .modify:hover {
      color: #000 !important;
    }
    .MuiOutlinedInput-inputMarginDense {
      background: #f4f4f4;
    }
    .MuiFormControl-marginDense {
      width: 100%;
    }
    .button {
      display: block;
      background: #b2c7d4;
      font-size: 11px;
      color: white;
      box-shadow: none;
      border: 2px solid #b2c7d4;
    }
    .button:hover {
      background-color: #014671;
      color: #fff;
      border: 2px solid #014671;
    }
    .cancel-btn {
      border: 2px solid #014671;
      font-size: 11px;
      box-shadow: none;
      background-color: #014671;
      color: #fff;
      text-transform: unset;
      line-height: 16px;
      padding: 10px 0px;
      display: block;
      width: 93%;
      margin: 0 0 0 10px;
      text-align: center;
    }
    .cancel-btn:hover {
      background-color: #014671;
      color: #fff;
    }

    p:hover {
      cursor: pointer;
    }
    .buttonContainer {
      justify-content: flex-end;
    }
    .modify {
      text-align: left;
      margin: 13px 0px;
      font-size: 17px;
      font-weight: 700;
      color: blue;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .topNavigation {
    text-align: left;
    margin: 10px 0 20px;
    font-weight: 500;
    font-size: 17px;
    a {
      text-decoration: none;
      color: #014671;
    }
    a:hover {
      cursor: pointer;
    }
  }
}
.newUser {
  .UserNameCol {
    margin-bottom: 30px;
    .selectUserRole {
      display: flex;
      align-items: center;
    }
    .MuiFormControl-marginDense {
      width: 100%;
      background: #f3f3f3;
    }
  }
  table {
    width: 100%;
    .MuiSwitch-thumb {
      background-color: #014671;
    }
    .MuiSwitch-track {
      background-color: #b2c7d4;
    }
    tr:first-child {
      line-height: 40px;
    }
  }
  .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
    background-color: #014671;
  }
}

.createBtn {
  margin: 20px !important;
  background-color: #014671 !important;
  text-transform: unset !important;
}

.MuiButton-containedSecondary {
  color: #fff;
  background-color: #f50057 !important;
}

.add-user {
  .MuiPaper-root.MuiDialog-paper.MuiDialog-paperScrollPaper {
    width: 741px !important;
    margin: 0;
    padding: 0;
  }
  .MuiTypography-h6 {
    .MuiGrid-grid-xs-11 {
      font-weight: bold;
    }
  }
  .MuiGrid-container.MuiGrid-grid-xs-12 {
    width: 49%;
    display: inline-block;
    margin-right: 10px;
    margin-bottom: 20px;
    vertical-align: top;
    &:nth-child(even) {
      margin-right: 0;
    }
    .MuiGrid-root.label.MuiGrid-item.MuiGrid-grid-xs-12 {
      font-size: 16px;
      line-height: 22px;
      font-weight: bold;
    }
    .MuiFormControl-marginDense {
      font-size: 16px;
      line-height: 22px;
      color: #a1a1a1;
    }
    #multiselectContainerReact {
      margin-top: 8px;
      input[type="text"] {
        padding-top: 5.5px;
        padding-bottom: 5.5px;
        margin: 0;
      }
    }
  }
  .MuiDialogActions-root.MuiDialogActions-spacing {
    display: block;
    padding: 20px !important;
    .MuiButton-containedSecondary {
      float: left;
      border: 1px solid #d8d8d8;
      border-radius: 3px;
      font-size: 16px;
      line-height: 24px;
      padding: 15px 47px;
      font-weight: bold;
      background: transparent !important;
      color: #d8d8d8;
      box-shadow: none;
    }
    .MuiButton-containedPrimary {
      float: right;
      margin: 0 !important;
      background: #4c337b !important;
      border-radius: 3px;
      font-size: 16px;
      line-height: 24px;
      padding: 15px 47px;
      font-weight: bold;
    }
  }
}
