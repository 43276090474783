.greenshoots-label {
  width: 100%;
  // box-shadow: 0 0 2px 1px rgba(0, 0, 0, 0.1) !important;
  padding: 10px;
  margin: 5px;
  .MuiFormControlLabel-label {
    color: black!important;
  }
  .MuiFormControl-root {
    width: 100% !important;
    max-width: 100% !important;
  }
}
.MuiFormControlLabel-root {
  margin-left: 0px !important;
}
.dropdown-input {
  .MuiFormControl-root {
    width: 100%;
    top: -14px;
  }
}

.file-upload {
  position: relative;
  top: 15px;
  .MuiButton-containedPrimary {
    background-color: #fff !important;
    color: grey!important;
    border: 2px dashed grey!important;
  }
  input {
    position: absolute;
    left: 0;
    top: -12px;
    opacity: 0;
  }
}

.chip {
  margin: 2px;
}

.chips {
  display: flex;
  flex-wrap: wrap;
}

.phoneNumberContainer{
  width: 100%;
  .phoneNumberError{
    font-size: 0.75rem;
    font-family: "Roboto", "Helvetica", "Arial", sans-serif;
    color: #f44336;
    float: left;
  }
  .PhoneInputInput {
    background: #F4F4F4;
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    margin-top: 8px;
    margin-bottom: 4px;
    padding: 18.5px 14px;
    padding-top: 10.5px;
    padding-bottom: 10.5px;
    font-size: 16px;
  }
  .PhoneInputCountry {
        margin-right: 15px;
        .PhoneInputCountryIcon  {
          width: 30px;
          height: 20px;
        }
  }
}